import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as storage from "src/utils/storage";
import Selection from "./Selection";
import ConfirmEmail from "./ConfirmEmail";
import Success from "./Success";
import {
  redeemReward,
  getRewardBalance,
} from "src/api";

const Redeem = ({ setIsLoggedIn, headLess }) => {
  const steps = {
    selection: "selection",
    confirm: "confirm",
    process: "process",
    success: "success",
  }
  
  const navigate = useNavigate();
  const notifyError = (message) => toast.error(message);
  const [rewardsBalance, setRewardsBalance] = useState(0);   
  const loggedInEmail = storage.getEmail();
  const userID = storage.getLoggedInUserID();
  const [redemptionInfo, setRedemptionInfo] = useState({
    step: steps.selection,
    redeemType: "dollar",
    redeemAmount: 0,    
    email: loggedInEmail,
  });
  
  useEffect(() => {   
    if (!storage.isUserLoggedIn()) {
      setIsLoggedIn(false);
      navigate("/login");
    } 

    getRewardBalance({ userID }).then((res) => {
      if (res && res.balance) {
        setRewardsBalance(res.balance.toFixed(2));
      }
    });
    
  }); 
  
  const setNextStep = (data) => {
    setRedemptionInfo({...redemptionInfo, ...data});
    if(data.step === steps.process){
      const payload = {
        userID,
        amount:redemptionInfo.redeemAmount * -1,
        fulfillmentVendor: redemptionInfo.redeemType==="dollar"?"tremendous":"onetribe",
        email: redemptionInfo.email,
      }
      redeemReward(payload).then((res) => {
        if (res && res.balance) {          
          setRedemptionInfo({...redemptionInfo, step:steps.success});
        }
      })
      .catch((err) => {
        setRedemptionInfo({...redemptionInfo, step:steps.confirm});
        notifyError(err.message);
      });
    }
  }

  switch(redemptionInfo.step) {
    case steps.selection:
      return <Selection setIsLoggedIn={setIsLoggedIn} headLess = {headLess} rewardsBalance= {rewardsBalance} setNextStep={setNextStep}/>;
    case steps.confirm:
      return <ConfirmEmail setIsLoggedIn={setIsLoggedIn} headLess = {headLess} setNextStep={setNextStep} redemptionInfo={redemptionInfo}/>;    
    default:
      return <Success setIsLoggedIn={setIsLoggedIn} headLess = {headLess} setNextStep={setNextStep} redemptionInfo={redemptionInfo}/>;
  }
};
export default Redeem;
