import React from "react";

import { LoggedInMenuContainer, YouAreLoggedInContainer, LoginButton } from "src/app/components/styled";
import colors from "src/app/colors";
import * as storage from "src/utils/storage";

const LoggedInBanner = ({ loggedInEmail, setIsLoggedIn }) => {
  const onLogout = () => {
    storage.remove();
    setIsLoggedIn(false);
    window.location.href = "/login";
  };

  return (
    <LoggedInMenuContainer>
      <LoginButton bg={colors.white} color={colors.gray}>
        <YouAreLoggedInContainer>
          You are logged in as{" "}
          <span style={{ color: colors.darkBlue }}>{loggedInEmail}</span>
        </YouAreLoggedInContainer>
      </LoginButton>
      <LoginButton
        bg={colors.lightGreen}
        color={colors.darkBlue}
        onClick={() => onLogout()}
      >
        Logout
      </LoginButton>
    </LoggedInMenuContainer>
  );
};

export default LoggedInBanner;
