import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  HeaderDash,
  MainContainer,
  PageContainer,
  RedeemConfirmContainer,
  SubTitleDash,
  SubHeaderDash,
} from "src/app/components/styled";
import colors from "src/app/colors";
import * as storage from "src/utils/storage";
import { Button } from "../styled";
import DollarIcon from "src/app/assets/icons/dollar.svg";
import LogoBanner from "src/app/components/LogoBanner";
import { dollarsToTrees } from "../constants";

const Redeem = ({ setIsLoggedIn, headLess, redemptionInfo }) => {
  const navigate = useNavigate();
  
  useEffect(() => {   
    if (!storage.isUserLoggedIn()) {
      setIsLoggedIn(false);
      navigate("/login");
    } 
  }); 

  const rewardType = redemptionInfo.redeemType === "dollar" ? "Gift Card" : `${dollarsToTrees[redemptionInfo.redeemAmount]} Trees`
  const rewardText = redemptionInfo.redeemType === "dollar" ? "in the form of a" : "to protect"
  return (
    <>          
      <PageContainer >
        <LogoBanner headLess={headLess} />
          <Box textAlign="center" style={{padding:"50px"}}>
          <HeaderDash><img src={DollarIcon} style={{width: "36px", height: "34px", marginRight: "15px"}} alt="" />Success!</HeaderDash>
          </Box>                  
          <MainContainer>             
              <RedeemConfirmContainer style={{padding:"50px"}}>                                          
            <SubTitleDash style={{ marginBottom: "25px " }}>You have successfully redeemed ${redemptionInfo.redeemAmount} {rewardText} <SubHeaderDash style={{ display: "inline" }}> {rewardType}</SubHeaderDash></SubTitleDash>                                          
              </RedeemConfirmContainer>                 
          </MainContainer>
          <MainContainer>          
          <Button onClick={() => navigate("/")} bc={colors.lightGreen} color={colors.darkBlue} style={{ margin: "25px auto" }}>Back to Profile</Button>
          </MainContainer> 
      </PageContainer>
    </>
  );
};
export default Redeem;
