import {
  HyperLink,
  ThinkEnergyLogoContainer2,
  ThinkEnergyLogo,
} from "src/app/components/styled";
import Logo from "src/app/assets/icons/logo.png";

const LogoBanner = ({ headLess }) => {
  return (
    <>
      {!headLess && (
        <ThinkEnergyLogoContainer2>
          <HyperLink
            href="https://thinkenergy.com/"
            target="_blank"
            rel="noreferrer"
          >
            <ThinkEnergyLogo src={Logo} alt="" />
          </HyperLink>
        </ThinkEnergyLogoContainer2>
      )}
    </>
  );
};

export default LogoBanner;
