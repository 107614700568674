import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  MainContainer,
  PageContainer,
  ToggleSwitchContainer,
  ToggleButton,
  RedeemOptionContainer,
  SubTitleDash,
  SubHeaderDash,
  RedemptionSelect,
  HeaderDash,
} from "src/app/components/styled";
import colors from "src/app/colors";
import RedeemMoneyIcon from "src/app/assets/icons/redeem_money.png";
import RedeemTreeIcon from "src/app/assets/icons/redeem_tree.png";
import * as storage from "src/utils/storage";
import { Button } from "../styled";
import LogoBanner from "src/app/components/LogoBanner";
import { dollarsToTrees } from "../constants";

const Redeem = ({ setIsLoggedIn, headLess, rewardsBalance, setNextStep }) => {
  const navigate = useNavigate();
  const [bankSelected, setBankSelected] = useState(false);
  const [dollarAmount, setDollarAmount] = useState(25);
  const [treeAmount, setTreeAmount] = useState(25);
  
  
  useEffect(() => {   
    if (!storage.isUserLoggedIn()) {
      setIsLoggedIn(false);
      navigate("/login");
    } 
  });

 

  const toggleModeSwitch = () => {
    setBankSelected(!bankSelected);
  };  

  const redeemDollar = () => {   
    console.log(dollarAmount, rewardsBalance)
    if(parseFloat(dollarAmount) > rewardsBalance){      
      return;
    }
    setNextStep({step: "confirm", redeemType:"dollar", redeemAmount:dollarAmount } )    
  }

  const redeemTree = () => {   
    setNextStep({step: "confirm", redeemType:"tree", redeemAmount:treeAmount } )    
  }

  return (
    <>      
      <PageContainer>
        <LogoBanner headLess={headLess} />
        <Button onClick={(e) => navigate("/")} bc={colors.gray} style={{ maxWidth: "fit-content" }}> {"< "} Back</Button>
        <Box textAlign="center">
          <HeaderDash>Redeem</HeaderDash>
        </Box>
        <Box textAlign="center">
        </Box>
        <MainContainer>   
          <ToggleSwitchContainer textAlign="center">
            <ToggleButton selected={bankSelected} onClick={()=>setBankSelected(false)}>Bank</ToggleButton>       
            <ToggleButton selected={!bankSelected} onClick={()=>setBankSelected(false)}>Redeem</ToggleButton>       
          </ToggleSwitchContainer>          
        </MainContainer>
        <MainContainer>             
            <RedeemOptionContainer selected={bankSelected} onClick={toggleModeSwitch} >
              <img src={RedeemMoneyIcon} width="40%" height="auto" alt="" style={{float:"left", marginRight: "50px"}}/>              
              <div style={{display:"inline"}}>
              <SubHeaderDash>Cash & Gift Cards Redemption</SubHeaderDash>
              <SubTitleDash>
              A digital Gift Card gift card that offers unparalleled flexibility and convenience. It allows you to load a chosen amount of money onto a virtual card, which can be used for online and in-store purchases at millions of locations worldwide. With no physical card to worry about, it's the ideal solution for quick and secure gifting, online transactions, and managing your finances. Plus, VISA eCards never expire, ensuring your funds are available when you need them most. Enjoy the freedom to shop, pay bills, and more with ease, all within a few clicks or taps!
              </SubTitleDash>
              <RedemptionSelect  onChange={(e)=>setDollarAmount(parseFloat(e.target.value))}>
                  <option value="25">$25</option>
                  <option value="50">$50</option>
                  <option value="100">$100</option>
                  <option value="200">$200</option>
                </RedemptionSelect>
              </div>
              <Button disabled={dollarAmount > rewardsBalance} onClick={redeemDollar} bg={colors.lightGreen} color={colors.darkBlue} style={{ marginTop:"20px" }}>Redeem ${dollarAmount}</Button>
            </RedeemOptionContainer>                 
        </MainContainer>   
        <MainContainer>             
            <RedeemOptionContainer selected={bankSelected} onClick={toggleModeSwitch}>
              <img src={RedeemTreeIcon} width="40%" height="auto" alt="" style={{float:"left", marginRight: "50px"}}/>              
              <div style={{display:"inline"}}>
              <SubHeaderDash>Cash & Gift Cards Redemption</SubHeaderDash>
              <SubTitleDash>
              Think Energy and One Tribe are working together to protect rainforest with every purchase you make. Now when you purchase from Think Energy you automatically save trees in the rainforest and help improve the carbon footprint of your purchase.
              </SubTitleDash>
              <SubTitleDash>
              Think Energy is participating in the United Nations Framework for Climate Change to adopt the UN Sustainability Goals. Working together as one tribe to help fight climate change together.
              </SubTitleDash>
              <RedemptionSelect onChange={(e)=>setTreeAmount(parseFloat(e.target.value))}>
                {
                  Object.entries(dollarsToTrees).map(([key, value]) => {
                    return <option value={key}>{value} trees</option>
                  })
                }
                </RedemptionSelect>
              </div>
              <Button disabled={treeAmount > rewardsBalance} onClick={redeemTree} bg={colors.lightGreen} color={colors.darkBlue} style={{ marginTop:"20px"}}>Redeem ${treeAmount}</Button>
            </RedeemOptionContainer>                 
        </MainContainer>               
      </PageContainer>      
    </>
  );
};
export default Redeem;
