import styled from 'styled-components'
import { space, layout, typography, color } from 'styled-system'
import colors from 'src/app/colors.js'


export const Box = styled.div`
  ${space}
  ${layout}
  ${typography}
  ${color}
`

export const Row = styled(Box)`
  ${space}
  ${layout}
  ${typography}
  ${color}
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent ? props.justifyContent : `flex-start`};
  align-items: ${props => props.alignItems ? props.alignItems : `stretch`};
  overflow: ${props => props.overflow ? props.overflow : `none`};
`

export const TableRow = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-basis: 0;
    margin-top: 10px;
    padding-bottom: 10px;
    ${props => props.noBorder ? `` : `border-bottom: 2px solid white;`}

    @media (max-width: 640px) {
      display: ${(props) => (props.isHeader ? 'none' : 'flex')};
      flex-direction: column;
      
    }
    
`

export const TableCol = styled(Box)`
   float: left;
   width: 25%;
   flex-wrap: wrap;
   display: flex;
   margin-right: 10px;
   word-wrap: break-word;
   ${props => props.noOverflow ? `` : `overflow: auto;`}

   @media (max-width: 640px) {
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
    justify-content: ${props => props.justifyContent ? props.justifyContent : `space-between`};
    
  }
  
`

export const Container = styled(Box)`
  background: ${colors.lightBlue};
  border-radius: 20px;
  padding: 40px;
`

export const LoginContainer = styled(Container)`
  justify-content: center;
  width: 30%;
  scroll: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const YouAreLoggedInContainer = styled.span`
  @media (max-width: 768px) {
    margin: 0px;
  }
`;

export const LoginButtonContainer = styled(Row)`
  justify-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
  }

`;

export const PageContainer = styled(Box)`  
  margin: 10px 42px 60px 60px;  
  @media (max-width: 768px) {
    width: 95%;
    height: 25%;
    margin: 0px 10px;
    display: flex;
    flex-flow: column;
  }
`;

export const MainContainer = styled(Row)`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 1150px) {
    width: 100%;
    height: 25%;
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
  }
`;

export const AccountContainer = styled(Box)`  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  @media (min-width: 768px) {
    gap: 25px;
  }
`;

export const AccountStatusContainer = styled(Box)`
  background: ${colors.lightBlue};
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 25px;
  padding: 40px;
  background: var(--Background, #F1F3F9);
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;


export const AccountStatusText = styled(Container)`    
display: flex;
width: 209px;
height: 21.8px;
padding: 5px;
justify-content: center;
align-items: center;
gap: 20.9px;
flex-shrink: 0; 
  color: ${colors.white};
  background: ${colors.lightBlue};
  border-radius: 52.25px;
  background: var(--Accent--Organic-Teal, #7CD0BE);
  @media (max-width: 768px) {    
    
    
  }
`;

export const AccountStatusAction = styled(Box)`
  font-weight: 700;
  font-size: 11px;
  margin-top: 25px; 
  color: ${colors.royalBlue};
  @media (max-width: 768px) {    

  }
`;

export const YourReferralContainer = styled(Container)`
  padding-right: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    height: 25%;
    display: flex;
    flex-flow: column;
    margin-top: 0px;
  }
`;

export const ReferAndEarnContainer = styled(Container)`
  width: 100%;
  padding-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    width: 100%;
    height: 25%;
    display: flex;
    flex-flow: column;
  }
`;


export const HowItWorksContainer = styled(Container)`
  background: ${colors.lightBlue};
  border-radius: 20px;
  padding: 30px 40px;
  text-align: center;
  width: 100%;
  @media (max-width: 1150px) {
    width: 100%;
    height: 25%;
    padding: 0px;
    margin-top: 25px;
  }
  @media (min-width: 1150px) {
    margin-left: 25px;
  }
`;

export const HowItWorksHeaderContainer = styled(Row)`
  padding: 20px;
  @media (max-width: 768px) {
    display: block;    
  }
`;

export const HowItWorksItemsContainer = styled(Row)`
  justify-content: space-around;  
  padding: 0px 40px;
  @media (max-width: 768px) {
    width: 100%;
    height: 25%;
    padding: 2px;
    border: 2px;
  }
  @media (max-width: 460px) {
   display: flex;
   min-height: 500px;
   flex-direction: column;
   align-items: space-between;
  }
`;

export const ThinkEnergyLogoContainer = styled.img`
  width: 140px;
  height: auto;

  @media (max-width: 768px) {
    width: 20%;
    margin-top: 20px;
  }
`;

export const ThinkEnergyLogoContainer2 = styled(Row)`
  margin: 25px auto;
  width: 100px;
  height: auto;
  @media (max-width: 768px) {
    margin: 20px;
    width: 100px;
  }
`;

export const ThinkEnergyLogo = styled.img`
  width: 100px;
  height: auto;
`;

export const FreeEnergyLogoContainer = styled.img`
  position: relative;
  float: left;
  width: 5%;
  height: auto;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 20%;
  }
`;

export const LeftFooterHomeImage = styled.img`
  width: 35%;
  height: auto;
  margin-left: -100px;  
`;

export const RightFooterHomeImage = styled.img`
  width: 35%;
  height: auto;
  right: -14vw;
  position: absolute;
`;

export const ThinkLogoContainer = styled(Box)`
  display: flex;
  alignitems: center;
  margin-left: -150px; 
  left: -300px; 
  margin-top: -100px;
`;

export const TitleContainer = styled(Box)`
  display: flex;
  alignItems: center;
`
export const FooterLogoContainer = styled(Box)`
  position: absolute;  
  bottom: 18vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
export const Text = styled(Box)`
    
 `

export const Image = styled.img`
  ${space}
  ${layout}
  ${typography}
  ${color}
  display: flex;
  width: 36px;
  height: 34px;
`

export const ReferralColumnName = styled(Text)`
   display: ${(props) => (props.isHeader ? 'flex' : 'none')};
   font-size: 21px;
   font-weight: 500;
   color: ${(props) => (props.color ? props.color : colors.navy)};
    @media (max-width: 768px) {
      font-size: 15px;
      
    }

    @media (max-width: 640px) {
      display: flex;
      
    }
 `;

export const ReferralColumnValue = styled(Text)`
   font-size: 21px;
   
   color: ${(props) => (props.color ? props.color : colors.royalBlue)};
    @media (max-width: 768px) {
      font-size: 15px;
      
    }
 `;


export const LoggedInMenuContainer = styled(Box)`
  display: flex;
  alignitems: center;
  justify-content: end;
  margin: 10px;
  @media (max-width: 768px) {
    margin-left: 10px;
    padding: 0px;
  }
`;

export const AccountSubHeader = styled(Text)`
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  padding-top: 20px;
  padding-bottom: 20px;
  letter-spacing: -0.02em;

  /* Think Royal */

  color: #2830a6;
`;


export const Button = styled.button`
  background: ${(props) => (props.bg ? props.bg : colors.white)};
  border: 2px solid ${(props) => (props.bg ? props.bg : props.bc || colors.white)};
  border-radius: 24px;
  color: ${(props) => (props.color ? props.color : colors.gray)};  
  height: 36px;
  font-size: 18px;
  font-weight: 600;
  font-family: Barlow;
  font-style: normal;
  padding: 0px 40px;
  cursor:  pointer;
  margin-right: 10px;
  opacity: ${(props) => (props.disabled ? '0.6' : '1')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'hand')};
  pointer-events:${(props) => (props.disabled ? 'none' : '')};;
  
`;

export const LoginButton = styled(Button)`
  @media (max-width: 768px) {
    font-size: 13px;
    margin: 15px 0 ;
  }
`;



export const SendReminderButton = styled(Button)`
  
  display: flex;
  height: 45.15px;
  padding: 10.32px 20.64px;
  justify-content: flex-end;
  align-items: center;
  gap: 12.9px;
  border: 2px solid ${(props) => (props.bg ? props.bg : props.bc || colors.white)};  
  color: ${(props) => (props.color ? props.color : colors.gray)};  
  @media (max-width: 768px) {
  font-size: 12px;    
} 
`;

export const ForMoreInformationButton = styled(Button)`
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  
  @media (max-width: 768px) {
    font-size: 13px;
    margin: 10px !important;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;


export const Divider = styled(Box)`
    height: 2px;
    background: ${colors.white};
    width: 100%;
`
export const StatusIndicator = styled(Box)`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-top: 5px;
    margin-right: 5px;
    background: ${props => props.color ? props.color : colors.royalBlue};
`

export const InputTextField = styled.input`
  height: 32px;
  border-radius: 33px;
  border: 1px solid ${colors.white};    
  width: 100%;
  font-family: "Barlow Semi Condensed";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #5f6076;
  border: 1px solid #ffffff;
  padding-left: 32px;
`;

export const InputTextArea = styled.textarea`
    border-radius: 21px;
    border: 1px solid ${colors.white};
    padding: 64px;
    font-size: 13px;
    width: 100%;
    resize: vertical;
    font-weight: 500;
    font-family: Barlow;
    font-style: normal;
    line-height: 40px;
    line-space: 20px
    color: ${colors.gray};
`;
export const InputCheckbox = styled.input.attrs((props) => ({
  type: "checkbox",
}))`  
  width: 29px;
  height: 29px;  
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 8px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  height: 15vh;
  background: ${colors.blue};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 22px;
  position: absolute;
  bottom: -0vh;
  @media (max-width: 768px) {
    display: inline-grid;
    height: 10vh;
  }
`;

export const FooterContainerSticky = styled(FooterContainer)`
  height: 7vh;  
  position: fixed;
  left: 0;
  bottom: 0;
`;

export const MenuContainer = styled.div`
  display: none;
  justify-content: space-between;  
`;

export const HeaderDash = styled(Text)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 80px;
  /* identical to box height, or 114% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #25245a;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SubHeaderDash = styled(Text)`
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  gap: 30px;
  color: #2830a6;
`;

export const SubTitleDash = styled(Text)` 
  font-family: Barlow;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 167% */

  /* Think Gray */

  color: #5f6076;
`;

export const SubHeader2 = styled(Text)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 59px;
  /* or 120% */

  letter-spacing: -0.02em;
  /* Think Royal */

  color: #2830a6;
`;

export const SubHeader6 = styled(Text)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* or 171% */

  letter-spacing: -0.02em;

  /* Think Royal */

  color: ${(props) => (props.color ? props.color : colors.royal)};
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 150%;
  }
`;

export const TitleText = styled(Text)`
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 125%;
  
  text-align: center;
  letter-spacing: -0.02em;
  color: ${(props) => (props.color ? props.color : colors.darkBlue)};
  @media (max-width: 768px) {
    font-size: 125%;
  }
`;

export const LabelText = styled(Text)`
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  /* Think Gray */

  color: ${(props) => (props.color ? props.color : colors.gray)};

  @media (max-width: 1200px) {
    text-align: center;
  }
`;


export const GetFriendsText = styled(SubTitleDash)`
  width: 100%;
  margin-bottom: 30px;
  color: var(--Think-Gray, #5F6076);
  font-family: Barlow;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
  @media (max-width: 768px) {
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

export const HyperLink = styled.a`  
  color: ${(props) => (props.color ? props.color : colors.white)};
  text-decoration: none;
`;

export const ReferAndEarnRow = styled(Row)`
  display: flex;
  width: 100%;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const ReferAndEarnRowItem = styled(Row)`
  width: 50%;
  @media (max-width: 640px) {
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const SendEmailContainer = styled(Row)`
  justify-content: end;
  margin: 30px;
  @media (max-width: 640px) {
    justify-content: center;
  }
`;

const progressIndicatorSize = 270
const progressIndicatorRadius = 120
export const AccountStatusIndicator = styled.svg`
  width: ${progressIndicatorSize}px;
  height: ${progressIndicatorSize}px;
  
  transform: rotate(130deg);
`;

export const ProgressBackground = styled.circle`
  cx: ${progressIndicatorSize / 2}px;
  cy: ${progressIndicatorSize / 2}px;
  r: ${progressIndicatorRadius}px;
  fill: transparent;
  stroke-width: 7px;
  stroke: ${colors.progressBackground};
  stroke-dasharray: ${2 * 3.14 * progressIndicatorRadius * 0.8}px;
`;

export const PorgressIndicator = styled(ProgressBackground)`
  stroke-width: 12px;
  stroke-linecap: round;
  stroke: ${(props) => (props.color ? props.color : colors.lightGreen)};
  stroke-dasharray: ${2 * 3.14 * progressIndicatorRadius}px;
  stroke-dashoffset: ${(props) => ((2 * 3.14 * progressIndicatorRadius) - ((props.progress / 100) * ((2 * 3.14 * progressIndicatorRadius) * 0.8)))}px;
  
`;

export const ReferralStatusText = styled(Container)`    
display: flex;
height: 21.8px;
padding: 5px 25px;
justify-content: center;
align-items: center;
gap: 20.9px;
flex-shrink: 0; 
  color: ${colors.white};
  background:  ${(props) => props.background};
  border-radius: 52.25px;  
  @media (max-width: 768px) {    
    
    
  }
`;

export const ToggleSwitchContainer = styled(Box)`
  display: flex;  
  background: ${colors.lightBlue};
  border-radius: 45px;
  
  width: 30%;
  margin: 0 auto;
  justify-content: space-between;    
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ToggleButton = styled(Button)`
  margin: 2px;
  display: flex;
  width: 50%;
  height: 45.15px;  
  justify-content: center;
  align-items: center;  
  border: 2px solid ${(props) => (props.selected ? colors.white : colors.lightBlue)};  
  background: ${(props) => (props.selected ? colors.white : colors.lightBlue)}; 
  color: ${(props) => (props.selected ? colors.royalBlue : colors.gray)};  
  @media (max-width: 768px) {
  font-size: 12px;    
} 
`;

export const RedeemOptionContainer = styled(Box)`    
  width: 100%;
  gap: 50px;
  padding: 50px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 25px;
  background: var(--Background, #F1F3F9);
  margin-bottom: 50px;
  gap: 74px; 
    @media (max-width: 768px) {
      justify-content: center;
    }
  `;



export const RedemptionDetail = styled(Box)`
  display: inline;
  flex-direction: column;
  align-items: flex-start;
  margin: 25px;
  flex: 1 0 0;
`;

export const RedemptionSelect = styled.select`
  color: var(--Text, #5F6076);
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 6px 0px 6px 6px;  
  border-radius: 2px; 
  float: left; 
  margin-top: 20px; 
  margin-right: 20px;
  /* h2 */
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 109.091% */
  letter-spacing: -0.5px;
`;

export const RedeemConfirmContainer = styled(Box)`  
  display: flex;
  align-items: center;
  width: 100%;  
  align-items: center;
  justify-content: center;
  flex-direction: column;  
  border-radius: 25px;
  background: var(--Background, #F1F3F9);  
  `;