

export const status = {
    enrolled: "Enrolled",
    pending: "Pending",
    notEnrolled: "Not Enrolled"
}

export const dollarsToTrees = {
    25: "100",
    125: "500",
    250: "1000"
}
