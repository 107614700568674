import { useState, useEffect } from "react";
import queryString from 'query-string'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import YourReferralAndSavings from "./containers/YourReferralAndSavings";
import Redeem from "./containers/Redeem";
import Login from "./containers/Login";
import Maintenance from "./containers/Maintenance";
import { isUserLoggedIn, setAccessToken, setRefreshToken, setLoggedInUserID, setLoggedInEmail } from "../utils/storage";
import "@fontsource/dm-sans";
import "@fontsource/barlow";
import { Toaster } from "react-hot-toast";

import * as storage from "src/utils/storage";
import Footer from "./components/Footer";
import LoggedInBanner from "./components/LoggedInBanner";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(isUserLoggedIn);
  const loggedInEmail = storage.getEmail();

  // use this to get the query string parameters to set the access token and refresh token
  const location = window.location
  const { hl, at, rt, u, e } = queryString.parse(location.search)
  const headLess = hl
  useEffect(() => {
    if (at && rt) {
      setAccessToken(at)
      setRefreshToken(rt)
      setLoggedInUserID(u)
      setLoggedInEmail(e)
      setIsLoggedIn(true)
    }
  }, [at, rt, u, e]);

  useEffect(() => { }, [isLoggedIn]);

  return (
    <>
      {process.env.REACT_APP_MAINTENANCE_MODE !== 'true' && (
        <>
          <Toaster />
          {!headLess && isLoggedIn && <LoggedInBanner loggedInEmail={loggedInEmail} setIsLoggedIn={setIsLoggedIn} />}
          <BrowserRouter>
            <Routes>
              <Route path="/redeem" element={<Redeem setIsLoggedIn={setIsLoggedIn} headLess={hl} />} />
              <Route path="/redeem/*" element={<Redeem setIsLoggedIn={setIsLoggedIn} headLess={hl} />} />
              <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
              <Route path="/" element={<YourReferralAndSavings setIsLoggedIn={setIsLoggedIn} headLess={hl} />} />
            </Routes>
          </BrowserRouter>
        </>
      )}
      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' && (
        <Maintenance headLess={hl} />
      )}
      {!headLess && <Footer />}
    </>
  );
};

export default App;
