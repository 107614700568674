import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  HeaderDash,
  MainContainer,
  PageContainer,
  RedeemConfirmContainer,
  SubTitleDash,
  SubHeaderDash,
  InputTextField,
} from "src/app/components/styled";
import colors from "src/app/colors";
import * as storage from "src/utils/storage";
import { Button } from "../styled";
import LogoBanner from "src/app/components/LogoBanner";

const Redeem = ({ setIsLoggedIn, headLess, redemptionInfo, setNextStep }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(redemptionInfo.email);
  useEffect(() => {   
    if (!storage.isUserLoggedIn()) {
      setIsLoggedIn(false);
      navigate("/login");
    }         
  }); 

  return (
    <>          
      <PageContainer >
        <LogoBanner headLess={headLess} />
        <Button onClick={(e) => setNextStep({ step: "selection" })} bc={colors.gray} style={{ maxWidth: "fit-content" }}> {"< "} Back</Button>
        <Box textAlign="center">
        <HeaderDash>Email Confirmation</HeaderDash>
        </Box>                  
        <MainContainer>             
            <RedeemConfirmContainer>                            
              <SubHeaderDash style={{marginTop: "50px ", marginBottom: "25px "}}>Confirm Email Address</SubHeaderDash>
              <SubTitleDash style={{ marginBottom: "25px "}}>An email receipt will be sent to this address</SubTitleDash>
              <InputTextField
                    placeholder="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    style={{width:"95%", textAlign: "center"}}
                  />
            <Button onClick={(e) => setNextStep({ step: "process", email })} bg={colors.lightGreen} color={colors.darkBlue} style={{ marginTop: "20px", marginBottom: "20px" }}>Redeem ${redemptionInfo.redeemAmount}</Button>
            </RedeemConfirmContainer>                 
        </MainContainer>       
        
      </PageContainer>
    </>
  );
};
export default Redeem;
