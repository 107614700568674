import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Row,
  Button,
  InputTextField,
  InputCheckbox,
  HeaderDash,
  AccountSubHeader,
  SubHeaderDash,
  SubHeader2,
  SubHeader6,
  AccountContainer,
  AccountStatusContainer,
  MainContainer,
  HowItWorksContainer,
  YourReferralContainer,
  PageContainer,
  AccountStatusText,
  AccountStatusAction,
  HowItWorksItemsContainer,
  HowItWorksHeaderContainer,
  GetFriendsText,
  ForMoreInformationButton,
  ReferAndEarnRow,
  ReferAndEarnRowItem,
  SendEmailContainer,
  Image,
} from "src/app/components/styled";
import toast from "react-hot-toast";
import colors from "src/app/colors";
import FriendsIcon from "src/app/assets/icons/your-referrals.svg";
import ShareIcon from "src/app/assets/icons/share.png";
import DollarIcon from "src/app/assets/icons/dollar.svg";
import {
  getReferrals,
  submitReferrals,
  submitMessage,
  getUserPrimaryAccount,
  getRewardBalance,
} from "src/api";
import * as storage from "src/utils/storage";
import ReferralTable from "./ReferralInfo";
import LogoBanner from "../LogoBanner";

const ACCOUNT_STATUS = {
  pending: "pending",
  active: "active",
  inactive: "inactive",
};

const REFERRAL_STATUS = {
  exists: "Already Exists",
  sent: "invitation_sent",
};

const mapAccountStatus = (accountStatus) => {
  if (accountStatus === ACCOUNT_STATUS.active) {
    return {
      status: "Active",
      progress: 100,
      action: "",
      progressColor: colors.lightGreen,
    };
  } else if (accountStatus === ACCOUNT_STATUS.inactive) {
    return {
      status: "Inactive",
      progress: 0,
      action: "",
      progressColor: colors.progressBackground,
    };
  }

  return {
    status: "Pending",
    progress: 50,
    action: "(No Action Required)",
    progressColor: colors.orange,
  };
};

const YourReferralAndSavings = ({ headLess }) => {
  const navigate = useNavigate();
  const [currentRefferals, setCurrentReferrals] = useState([]);
  const [invitees, setInvitees] = useState(
    [...Array(5)].map(() => {
      return { referral_name: "", referral_email: "", eligible: true };
    })
  );

  const [accountStatus, setAccountStatus] = useState(
    mapAccountStatus(ACCOUNT_STATUS.pending)
  );

  const [rewardsBalance, setRewardsBalance] = useState(0);

  useEffect(() => {
    getReferrals({ limit: 100 }).then((res) => {
      setCurrentReferrals(res.referrals);
    });

    const user_id = storage.getLoggedInUserID();
    getUserPrimaryAccount({ user_id }).then(
      (res) =>
        res &&
        res.details &&
        res.details.status &&
        setAccountStatus(mapAccountStatus(res.details.status))
    );

    getRewardBalance({ userID: user_id }).then((res) => {
      if (res && res.balance) {
        setRewardsBalance(res.balance.toFixed(2));
      }
    });
  }, []);

  const notifyError = (message) => toast.error(message);
  const notifySucess = (message) => toast.success(message);

  const onSubmitReferrals = () => {
    const user_id = storage.getLoggedInUserID();
    const referrals = invitees
      .map((referral) => ({
        ...referral,
        referral_email: referral.referral_email.trim(),
        referral_name: referral.referral_name.trim(),
      }))
      .filter((i) => i.referral_email !== "" && i.referral_name !== "");

    if (referrals.length === 0) {
      notifyError("Please fill up at least one referral information");
      return;
    }

    submitReferrals({ user_id, referrals })
      .then((res) => {
        if (res.result) {
          if (res.referrals) {
            const uniqueReferrals = res.referrals.filter((referral) => {return referral.status !== REFERRAL_STATUS.exists});
            setCurrentReferrals([...currentRefferals, ...uniqueReferrals]);
          }
          setInvitees(
            [...Array(5)].map(() => {
              return { referral_name: "", referral_email: "" };
            })
          );
          notifySucess("Referral Submitted!");
        }
      })
      .catch((e) => {
        notifyError(e.message);
      });
  };

  const onSendReminder = (id) => {
    const user_id = storage.getLoggedInUserID();
    submitMessage({ user_id, id, message_type: "reminder" })
      .then((res) => {
        if (res.result) {
          notifySucess("Reminder sent!");
        }
      })
      .catch(() => {
        notifyError("Problem occurred while sending reminder!");
      });
  };

  const onRedeemClick = () => {
    navigate("/redeem");
  };

  const onTextFieldChange = (handler, values, index, propName) => (e) => {
    const newValue = { ...values[index] };
    newValue[propName] = e.target.value;
    const newValues = [...values];
    newValues[index] = newValue;
    handler(newValues);
  };

  return (
    <>
      <PageContainer>
        <LogoBanner headLess={headLess} />
        <Box textAlign="center">
          <HeaderDash>Your referral & savings overview</HeaderDash>
        </Box>
        <MainContainer>
          <AccountContainer>
            <AccountStatusContainer>
              <Box>
              <AccountSubHeader>Your Account Status</AccountSubHeader>
              <AccountStatusText>{accountStatus.status}</AccountStatusText>
              <AccountStatusAction>
                {accountStatus.action || "(no action required)"}
              </AccountStatusAction>
              </Box>
            </AccountStatusContainer>
            <AccountStatusContainer>
            <Box>
            <AccountSubHeader>
                <SubHeaderDash>
                  <Image
                    src={DollarIcon}
                    alt=""
                  />
                  Your Bank Balance
                </SubHeaderDash>
              </AccountSubHeader>
              <SubHeaderDash>
                ${rewardsBalance}
                <Button color={colors.gray} onClick={() => onRedeemClick()}>
                  Redeem
                </Button>
              </SubHeaderDash>
            </Box>

            </AccountStatusContainer>
          </AccountContainer>
          <HowItWorksContainer>
            <HowItWorksHeaderContainer>
              <SubHeaderDash>How it works</SubHeaderDash>
              <GetFriendsText>
                Get a few friends and neighbors to choose Think Energy.
              </GetFriendsText>
            </HowItWorksHeaderContainer>
            <HowItWorksItemsContainer>
              <Box>
                <SubHeader6>Refer 3 friends and get a</SubHeader6>
                <SubHeader6>monthly rebate equal to</SubHeader6>
                <SubHeader2>10%</SubHeader2>
                <SubHeader6 color={colors.gray}>
                  of your Think Energy
                </SubHeader6>
                <SubHeader6 color={colors.gray}>
                  electricity supply charges
                </SubHeader6>
              </Box>

              <Box>
                <SubHeader6>Refer 10 friends and get a</SubHeader6>
                <SubHeader6>monthly rebate equal to</SubHeader6>
                <SubHeader2>40%</SubHeader2>
                <SubHeader6 color={colors.gray}>
                  of your Think Energy
                </SubHeader6>
                <SubHeader6 color={colors.gray}>
                  electricity supply charges
                </SubHeader6>
              </Box>

              <Box>
                <SubHeader6>Refer 20 friends and get a</SubHeader6>
                <SubHeader6>monthly rebate equal to</SubHeader6>
                <SubHeader2>100%</SubHeader2>
                <SubHeader6 color={colors.gray}>
                  of your Think Energy
                </SubHeader6>
                <SubHeader6 color={colors.gray}>
                  electricity supply charges
                </SubHeader6>
              </Box>
            </HowItWorksItemsContainer>
            <Row mt={4} mb="15px" justifyContent="left">
              <ForMoreInformationButton
                color={colors.gray}
                style={{ margin: "0px" }}
                onClick={() =>
                  window.open("https://thinkenergy.com/fec-terms/")
                }
              >
                {" "}
                Learn more
              </ForMoreInformationButton>
            </Row>
          </HowItWorksContainer>
        </MainContainer>
        <YourReferralContainer>
          <SubHeaderDash>
            <Image
              src={FriendsIcon}
              alt=""
            />
            Your Referrals
          </SubHeaderDash>
          <ReferralTable
            currentRefferals={currentRefferals}
            onSendReminder={onSendReminder}
          />
        </YourReferralContainer>
        <YourReferralContainer mt={3}>
          <SubHeaderDash>
            <Image
              src={ShareIcon}
              alt=""
            />
            Refer and earn
          </SubHeaderDash>
          <Row mt={"15px"}>
            {/* <Text
                color={colors.gray}
                fontWeight={400}
                fontSize={"12px"}
                mt={20}
                pt="2px"
              >
                Send a simple message for up to 5 friends at a time.
              </Text> */}
          </Row>
          {invitees.map((invitee, i) => (
            <Row mt={"30px"} alignItems="center" key={i}>
              <Text
                color={colors.gray}
                fontWeight={700}
                fontSize={"21px"}
                mr={"15px"}
              >
                {" "}
                {i + 1}
              </Text>
              <ReferAndEarnRow>
                <ReferAndEarnRowItem mr={"20px"}>
                  <InputTextField
                    placeholder="Full name"
                    value={invitee.referral_name}
                    onChange={onTextFieldChange(
                      setInvitees,
                      invitees,
                      i,
                      "referral_name"
                    )}
                  />
                </ReferAndEarnRowItem>
                <ReferAndEarnRowItem>
                  <InputTextField
                    placeholder="Email address"
                    value={invitee.referral_email}
                    onChange={onTextFieldChange(
                      setInvitees,
                      invitees,
                      i,
                      "referral_email"
                    )}
                  />
                </ReferAndEarnRowItem>
              </ReferAndEarnRow>
            </Row>
          ))}

          <SendEmailContainer>
            <Row alignItems="center" style={{ display: "none" }}>
              <InputCheckbox />
              <Text
                color={colors.gray}
                fontWeight={500}
                fontSize={"21px"}
                ml={"8px"}
              >
                Send me a copy of this email
              </Text>
            </Row>

            <Button
              bg={colors.lightGreen}
              color={colors.darkBlue}
              onClick={() => onSubmitReferrals()}
            >
              Send email
            </Button>
          </SendEmailContainer>
        </YourReferralContainer>
      </PageContainer>
    </>
  );
};
export default YourReferralAndSavings;
