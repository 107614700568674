import React from "react";
import { Text, Row, FooterContainerSticky, HyperLink } from "src/app/components/styled";
import colors from "src/app/colors";

const Footer = () => (
  <FooterContainerSticky>
    <Row>
      <Text color={colors.white} fontWeight={500} fontSize={"10px"} ml={40}>
        Copyright 2022 • Think Energy LLC |{" "}
        <HyperLink
          href="https://thinkenergy.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </HyperLink>
        {" "}•{" "}
        <HyperLink
          href="https://thinkenergy.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </HyperLink>
      </Text>
    </Row>
    <Row>
      <Text
        color={colors.white}
        fontWeight={500}
        fontSize={"16px"}
        mr={10}
        ml={40}
      >
        Questions?{" "}
      </Text>
      <Text
        color={colors.lightGreen}
        fontWeight={500}
        fontSize={"16px"}
        mr={40}
      >
        1-888-923-3633
      </Text>
    </Row>
  </FooterContainerSticky>
);

export default Footer;
