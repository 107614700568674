import {
  Box,
  PageContainer,
  HeaderDash,
  Container,
  SubHeader6,
} from "src/app/components/styled";
import LogoBanner from "src/app/components/LogoBanner";

const Maintenance = ({ headLess }) => {
  return (
    <>
      <PageContainer>
        <LogoBanner headLess={headLess} />
        <Box textAlign="center">
          <HeaderDash>System Maintenance</HeaderDash>
        </Box>
        <Container textAlign="center">
          <SubHeader6>
            We're updating the system to serve you better. Please check back soon.
          </SubHeader6>
        </Container>
      </PageContainer>
    </>
  );
};

export default Maintenance;
